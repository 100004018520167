import Divcard from "./Divcard";
import "./CountryFlags.css";
import { useState, useEffect } from "react";
import Collapsible from "./collapse";
import axios from 'axios';
import {
  useCollapse
} from "react-collapsed";
import { useNavigate, Link } from "react-router-dom";
import { loginAgent, RefreshToken, getAllCountry, getAllBundles } from './functions';
import { Buffer } from "buffer";
import { decrypt, encrypt } from '../crypto';
const CountryFlags = ({
  isHome
}) => {

  const navigation = useNavigate();
  const [i, seti] = useState(12)
  const [isPop, setisPop] = useState(true)
  const [expandedIndex, setexpandedIndex] = useState(null)
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
  //   const dataBody = JSON.stringify({
  //     username,
  //     password
  // });
  const dataBody = encrypt({
    username,
    password
  });
  useEffect(() => {
    const fetchCountries = async () => {
      const conf = {
        method: 'post',
        url: apiUrl + 'Countries',
        headers: {
          'Content-Type': 'application/json'

        },
        data: {
          token: dataBody
        }
      }
      try {
        const result = await axios(conf);
        const encryptedResponse = await result.data.countries
        const decryptedData = decrypt(encryptedResponse);
        
        setData(decryptedData);
        setIsPending(false);
      } catch (error) {
        console.error(`Error occurred while fetching data: ${error}`);
        setIsPending(false);
      }
    };
    const fetchPopCountries = async () => {
      const conf = {
        method: 'post',
        url: apiUrl + 'AllCountries',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          token: dataBody
        },
        params: {
          countries_req: ["USA", "GBR", "TUR", "DEU", "FRA", "ITA", "ESP", "ARE", "SAU", "CYP", "CHE", "GRC"]
        },
      }
      try {
        const result = await axios(conf);
        const encryptedResponse = await result.data
        const decryptedData = decrypt(encryptedResponse);
        setData(decryptedData);
        setIsPending(false);
      } catch (error) {
        console.error(`Error occurred while fetching data: ${error}`);
        setIsPending(false);
      }

    };

    (isHome) ? fetchPopCountries() : fetchCountries();
  }, []);



  return (
    <>

      {!isHome && isPending && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div class="loader"></div></div>}
      {!isHome && !isPending && data && <section className="country-flags1">
        <div className="popular-countries-list">
          <h3 className="popular-countries">Popular Countries</h3>
        </div>
        <div className="div-row">
          <div className="divrow16">

            {isPop && data && data.filter(item => [
              "USA", "GBR", "TUR", "DEU", "FRA", "ITA", "ESP", "ARE", "SAU", "CYP", "CHE", "GRC"
            ].includes(item.iso3_code)).map((item, index) => {
              return (
                <Collapsible
                  key={index}
                  index={index}
                  trsvg={`https://flagcdn.com/${item.iso2_code.toLowerCase()}.svg`}
                  turkey={item.country_name}
                  bundles={false}
                  isCountry={true}
                  searching={false}
                  expandedIndex={expandedIndex}
                  setExpandedIndex={setexpandedIndex}
                  iso3_code={item.iso3_code}
                  isHome={isHome}
                />
              );
            })}
            {!isPop && data && data.map((item, index) => {
              return (
                <Collapsible
                  key={index}
                  index={index}
                  trsvg={`https://flagcdn.com/${item.iso2_code.toLowerCase()}.svg`}
                  turkey={item.country_name}
                  bundles={false}
                  isCountry={true}
                  searching={false}
                  expandedIndex={expandedIndex}
                  setExpandedIndex={setexpandedIndex}
                  iso3_code={item.iso3_code}
                  isHome={isHome}
                />
              );
            })}

            <img
              className="whatsapp-logo6d974d7esvg-icon6"
              id="wtsp-logo"
              loading="eager"
              alt=""
              src="/whatsapplogo6d974d7esvg4@2x.png"
              onClick={() => {
                window.open("https://api.whatsapp.com/send/?phone=447482774201")
              }}
            />
            <Link to="/trend-AI">
              <img
                className="TrendAI-Logo"
                alt=""
                src="/chatbot.png"
                title="Contact Our AI"
              />
            </Link>
          </div>
          <button className="button19" onClick={(e) => {
            // seti(data.length);
            setisPop(false);
            e.currentTarget.style.display = 'none';
            document.querySelector('.popular-countries-list').style.display = 'none';
          }}>
            <b className="view-all-countries1" >View all countries</b>
          </button>
        </div>
      </section>}

      {isHome && isPending && <div className="country-flags1" style={{ height: "500px", alignItems: "center", padding: "100px" }}><div class="loader"></div></div>}
      {isHome && !isPending && data &&
        <div className="div-row">
          <div className="divrow16">

            {data && data.slice(0, i).map((item, index) => {
              return (
                <Collapsible
                  key={index}
                  index={index}
                  trsvg={`https://flagcdn.com/${item.iso2_code.toLowerCase()}.svg`}
                  turkey={item.name}
                  bundles={item.bundles}
                  isCountry={true}
                  searching={false}
                  expandedIndex={expandedIndex}
                  setExpandedIndex={setexpandedIndex}
                  isHome={isHome}

                />
              );
            })}
          </div>
          <button className="button19" onClick={(e) => {
            navigation('/plans')
            // seti(data.length);
            // e.currentTarget.style.display = 'none';
            // document.querySelector('.popular-countries-list').style.display = 'none';
          }}>
            <b className="view-all-countries1" >View all countries</b>
          </button>
        </div>}
    </>
  );
};

export default CountryFlags;
