import MontyeSIMBenefits from "../components/MontyeSIMBenefits";
import MontyMobileDivider from "../components/MontyMobileDivider";
import Divfooter1 from "../components/Divfooter1";
import "./WwwmontyesimcomByHtmltod1.css";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
  FaTiktok,
  FaSquarePinterest
} from "react-icons/fa6";
import { Link } from "react-router-dom";

import Navigation1 from "../components/Navigation1";
const WwwmontyesimcomByHtmltod1 = () => {
  return (
    <div className="wwwmontyesimcom-by-htmltod2">
      <Navigation1 />
      <div className="frame-group">
        <div className="montye-s-i-m-wrapper">
          <div className="montye-s-i-m">
            <div className="contactarrow">
              <div className="stayconnected">
                <img
                  className="contact-arrowa4cb5823svg-icon"
                  loading="eager"
                  alt=""
                  src="/contactarrowa4cb5823svg2.svg"
                />
                <div className="whatsapplogo">
                  <b className="stay-connected">Stay connected</b>
                </div>
              </div>
            </div>
            <h1 className="heading-1">About us</h1>
          </div>
        </div>
        <div className="say-goodbye-to-container">
          <p className="say-goodbye-to">
            Say goodbye to the physical SIM card and welcome to the latest
          </p>
          <p className="innovative-technology-that">
            innovative technology that will make your life easier than ever:
            eSIM!
          </p>
        </div>
      </div>
      <MontyeSIMBenefits />
      <MontyMobileDivider />
      <Divfooter1 />
      <div className="divfooter-sub3">
          <div className="qr-curves-b-s-v-g">
          <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/privacy-policy"
        > <b >Privacy Policy</b></Link>
        <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/refund-policy"
        > <b >Refund Policy</b></Link>
        <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/term-condition"
        > <b >Terms & Conditions</b></Link>


            <div className="social-media">
              <b >Contact Us</b>

              <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareFacebook />
              </a>
              <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareInstagram />
              </a>
              <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareYoutube />
              </a>
              <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </a>
              <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquarePinterest />
              </a>

            </div>
          </div>
          <b>© 2024 Trend eSIM</b>
        </div>
    </div>
  );
};

export default WwwmontyesimcomByHtmltod1;
