import "./DivcolMd1.css";
import { useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Buffer } from "buffer";
import { encrypt } from '../crypto';


const DivcolMd1 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const SentMial = async () => {
    if (email === "" || message === "" || !email.includes("@") || ![
      "mail.com", "gmail.com", "yahoo.com", "hotmail.com",
      "outlook.com", "aol.com", "icloud.com", "protonmail.com",
      "zoho.com", "yandex.com", "tutanota.com", "gmx.com",
      "yopmail.com", "mail.ru", "inbox.lv", "t-online.de",
      "web.de", "gmx.net", "mail.de", "email.com", "email.cz",
      "seznam.cz", "centrum.cz"].includes(email.split("@")[1])) {
      toast.error("Please fill all the fields with valid values",
        {
          style: {

            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        });
    } else {
      const token = encrypt({
        email,
        message,
        data_access: {
          username,
          password
        }
      });
      const conf = {
        method: 'post',
        url: apiUrl + 'send',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: token,
        },

      }
      const sendMailPromise = await axios(conf)
      toast.promise(
        sendMailPromise,
        {
          pending: 'Sending message...',
          success: 'Message sent successfully',
          error: 'Error sending message'
        }
        ,
        {
          style: {
            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        }
      ).then(() => {
        setEmail("");
        setMessage("");
      });
    }
  }
  return (
    <section className="divcol-md-10">
      <div className="helprequestframe">
        <div className="for-all-inquiries">
          <div className="label-email">
            <img
              className="contact-arrowa4cb5823svg-icon2"
              alt=""
              src="/contactarrowa4cb5823svg-1.svg"
            />
          </div>
          <b className="contact-us2">Contact us</b>
        </div>
      </div>
      <div className="needhelpheader">
        <div className="footerlinkgroup">
          <h1 className="heading-16">Need some help?</h1>
          <h3 className="for-all-inquiries1">
            For all inquiries, please email us using the form below. Our team is
            there for you 24/7!
          </h3>
        </div>
        <div className="emaillabelframe">
          <b className="label-email1">Email</b>
          <div className="sendbuttonframe">
            <input type="text" className="input1" value={email} onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }} />
          </div>
          <b className="label-message">Message</b>
          <textarea className="textarea" rows={13} cols={43} value={message} onChange={(e) => {
            setMessage(e.target.value);
          }} />
        </div>
      </div>
      <button className="button13" onClick={async (e) => {
        e.preventDefault();
        await SentMial();
      }}>
        <b className="send-message">Send message</b>
      </button>
    </section>
  );
};

export default DivcolMd1;
