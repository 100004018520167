import MessageTextarea from "./MessageTextarea";
import "./DivcolMd7.css";
import { useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Buffer } from "buffer";
import { encrypt } from '../crypto';
const DivcolMd7 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const SentMial = () => {
    if (email === "" || message === "" || !email.includes("@") || ![
      "mail.com", "gmail.com", "yahoo.com", "hotmail.com",
      "outlook.com", "aol.com", "icloud.com", "protonmail.com",
      "zoho.com", "yandex.com", "tutanota.com", "gmx.com",
      "yopmail.com", "mail.ru", "inbox.lv", "t-online.de",
      "web.de", "gmx.net", "mail.de", "email.com", "email.cz",
      "seznam.cz", "centrum.cz"].includes(email.split("@")[1])) {
      toast.error("Please fill all the fields with valid values",
        {
          style: {

            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        });
    } else {
      const token = encrypt({
        email,
        message,
        data_access: {
          username,
          password
        }
      });
      const conf = {
        method: 'post',
        url: apiUrl + 'send',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: token,
        },
      }
      const sendMailPromise = axios(conf)
      toast.promise(
        sendMailPromise,
        {
          pending: 'Sending message...',
          success: 'Message sent successfully',
          error: 'Error sending message'
        }
        ,
        {
          style: {
            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        }
      ).then(() => {
        setEmail("");
        setMessage("");
      });
    }
  }
  return (
    <div className="divcol-md-125">
      <div className="email-contact-form">
        <MessageTextarea
          contactArrowa4cb5823svg="/contactarrowa4cb5823svg-fill.svg"
          propPadding="unset"
          propPadding1="0px 0px var(--padding-4xs)"
          propDisplay="inline-block"
          propDisplay1="inline-block"
        />
        <div className="email-input-field">
          <div className="send-message-button">
            <b className="label-email6">Email</b>
            <div className="input-wrapper">
              <input type="text" className="input12" value={email} onChange={(e) => {
                 setEmail(e.target.value.toLowerCase());
              }} />
            </div>
            <b className="label-message2">Message</b>
          </div>
          <textarea className="textarea4" rows={13} cols={46} value={message} onChange={(e) => {
            setMessage(e.target.value);
          }} />
        </div>
      </div>
      <button className="button31" onClick={(e) => {
        e.preventDefault();
        SentMial();

      }}>
        <b className="send-message3">Send message</b>
      </button>
    </div>
  );
};

export default DivcolMd7;
