import { useState, useEffect } from "react";
import DivcolMd8 from "../components/DivcolMd8";
import "./WwwmontyesimcomByHtmltod7.css";
import Navigation1 from "../components/Navigation1";
import {
  useNavigate,
  useLocation,
  Link
} from "react-router-dom";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
  FaTiktok,
  FaSquarePinterest,
  FaRegCopy
} from "react-icons/fa6";
import { Buffer } from "buffer";
const WwwmontyesimcomByHtmltod9 = () => {

  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    redirect_status: null,
    response: null,
    smdpAddress: null,
    ActivationCode: null,
    attachment: null,
    buffer: null,
    base64String: null,
    provider: null
  });
  useEffect(() => {
    if (location.state) {
      const { redirect_status, response } = location.state;
      const { smdpAddress, ActivationCode, attachment, provider } = response;
      let buffer = null, base64String = null;
      if (provider === "MontyEsim") {
        buffer = Buffer.from(attachment, 'base64');
        base64String = buffer.toString('base64');
      }
      setState({
        redirect_status,
        response,
        smdpAddress,
        ActivationCode,
        attachment,
        buffer,
        base64String,
        provider
      });
      // console.log(state, "if");
    } else {
      // console.log(location.state, "else");
      // If location.state is null, navigate to the plans page
      navigate("/plans");
    }
  }, [location, navigate]);

  useEffect(() => {
    function handleScroll() {
      const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon");
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

      if (element) {
        if (scrollHeight - scrollTop === clientHeight) {
          // Scroll has reached the end
          // console.log("Scroll reached the end");
          element.style.bottom = "75%";
        } else {
          element.style.bottom = "-1%";
        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <div className="wwwmontyesimcom-by-htmltod8">
      <Navigation1 />
      <section className="navigation-bar">
        <div className={`payment-status ${state.redirect_status === 'succeeded' ? 'success' : 'failed'}`}>
          {(state.redirect_status === 'succeeded') && <img src="/valid.png" alt="" style={{
            width: '30%',
            height: '30%',
            // borderRadius: '50%',
            objectFit: 'contain',
            alignItems: 'center',

          }} />}
          <div className="payment-status-text">

            <h2>Payment {state.redirect_status === 'succeeded' ? 'successful' : 'failure ❌'}</h2>
            {
              (() => {
                if ((state.redirect_status === 'succeeded')) {
                  return <p>The code Qr was sent to your email,<br />
                    please scan it to install it on your phone.<br />
                    Please refer to the "How it works" page for more detailed instructions.
                  </p>
                } else {
                  return <p>Your order has been failed to place.<br />
                    Please contact the support at support@trendesim.com to get more information for helping you.
                  </p>
                }
              })()
            }
          </div>
          <div className="payment-status-qr">
            {
              (state.redirect_status === 'succeeded' && state.attachment) &&
              <>

                <img
                  className="payment-status-img"
                  src={state.provider === "MontyEsim" ? `data:image/png;base64,${state.base64String}` : state.attachment}
                  alt="qr-code"

                />

                <div className="payment-status-address-activation">
                  <h3>SM-DP+ Address:</h3>
                  <span>
                    <p>{state.smdpAddress}</p>
                    <FaRegCopy style={{
                      cursor: "pointer",
                      fontSize: '20px'

                    }} onClick={() => {
                      navigator.clipboard.writeText(`http://${state.smdpAddress.toLowerCase()}/`);
                      setCopied(true);
                      setTimeout(() => setCopied(false), 2000)
                    }}

                    />
                    {copied && <span style={{
                      backgroundColor: 'black',
                      color: 'white',
                      textAlign: 'center',
                      paddingVertical: '5px',
                      paddingHorizontal: 0,
                      borderRadius: '6px',
                      position: 'absolute',
                      bottom: '80%',
                      right: '-10%',
                      width: '100px',
                      justifyContent: 'center'
                    }}>Copied!</span>}
                  </span>
                  <br />
                  <h3>Activation Code:</h3>
                  <span>
                    <p>{state.ActivationCode}</p>
                    <FaRegCopy style={{
                      cursor: "pointer",
                      fontSize: '20px'

                    }} onClick={() => {
                      navigator.clipboard.writeText(state.ActivationCode);
                      setCopied1(true);
                      setTimeout(() => setCopied1(false), 2000)
                    }} />
                    {copied1 && <span style={{
                      backgroundColor: 'black',
                      color: 'white',
                      textAlign: 'center',
                      paddingVertical: '5px',
                      paddingHorizontal: 0,
                      borderRadius: '6px',
                      position: 'absolute',
                      bottom: '80%',
                      right: '-10%',
                      width: '100px',
                      justifyContent: 'center'
                    }}>Copied!</span>}
                  </span>

                </div>
              </>}

            <div className="payment-status-buttons">
              {(state.redirect_status === 'succeeded') && <button onClick={() => {
                navigate("/how-it-works")
              }}>
                Go to User Guide
              </button>}
              {!(state.redirect_status === 'succeeded') && <button onClick={() => {
                navigate("/contact-us")
              }}>
                Contact Support
              </button>}
              <button onClick={() => {
                navigate("/plans")
              }}>
                Go back to Shop plans
              </button>
            </div>
          </div>

        </div>
      </section>
      <section className="whats-app-logo1">
        <img
          className="whatsapp-logo6d974d7esvg-icon"
          loading="eager"
          alt=""
          src="/whatsapplogo6d974d7esvg6@2x.png"
          onClick={() => {
            window.open("https://api.whatsapp.com/send/?phone=447482774201")

          }}
        />
        <footer className="divfooter2">
          <div className="divrow10">
            <DivcolMd8
              phoneIcon0eb21fd3svg="/phoneicon0eb21fd3svg.svg"
              phone="Phone"
              link442073705310="+44 7482 774201"
            />
            <DivcolMd8
              phoneIcon0eb21fd3svg="/emailicond2ebb660svg.svg"
              phone="Email"
              link442073705310="support@trendesim.com"
              propMarginLeft="-3.95px"
              propPadding="var(--padding-mini) var(--padding-smi)"
              propWidth="22px"
              propTextDecoration="none"
            />
            <div className="divcol-md-42">
              <div className="divrow-parent">
                <div className="divrow11">
                  <div className="divfooter-section-icon2">
                    <div className="locationicon1ab2e5b5svg">
                      <div className="locationicon1ab2e5b5svg-fill">
                        <img
                          className="locationicon1ab2e5b5svg1"
                          loading="eager"
                          alt=""
                          src="/locationicon1ab2e5b5svg.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="office">Office</h3>
              </div>
              <div className="headquarters-bridge-house-container">
                <p className="headquarters-bridge-house">
                  2nd Floor College House 17 King Edwards Road,
                </p>
                <p className="queen-victoria-street">
                  RUISLIP, London, HA4 7AE, UNITED KINGDOM
                </p>
              </div>
            </div>
          </div>
        </footer>
      </section>
      <div className="divfooter-sub3">
        <div className="qr-curves-b-s-v-g">
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/privacy-policy"
          > <b >Privacy Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/refund-policy"
          > <b >Refund Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/term-condition"
          > <b >Terms & Conditions</b></Link>


          <div className="social-media">
            <b >Contact Us</b>

            <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareFacebook />
            </a>
            <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareInstagram />
            </a>
            <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareYoutube />
            </a>
            <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquarePinterest />
            </a>

          </div>
        </div>
        <b>© 2024 Trend eSIM</b>
      </div>
    </div>
  );
};

export default WwwmontyesimcomByHtmltod9;
