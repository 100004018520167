import DivcolMd from "./DivcolMd";
import "./Emailiconframe.css";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
  FaTiktok,
  FaSquarePinterest
} from "react-icons/fa6";
import { Link } from "react-router-dom";


const Emailiconframe = () => {
  return (
    <footer className="emailiconframe">
      <div className="divfooter3">
        <DivcolMd
          divfooterSectionIcon="/divfootersectionicon.svg"
          phone="Phone"
          link442073705310="+44 7482 774201"
        />
        <DivcolMd
          divfooterSectionIcon="/divfootersectionicon-1.svg"
          phone="Email"
          link442073705310="support@trendesim.com"
          propPadding="0px var(--padding-xl) var(--padding-19xl) var(--padding-2xl)"
          phoneDisplay="inline-block"
          phoneWidth="unset"
        />
        <div className="divcol-md-44">
          <img
            className="divfooter-section-icon4"
            alt=""
            src="/divfootersectionicon-2.svg"
          />
          <b className="office1">Office</b>
          <b className="headquarters-bridge-house-container1">
            <span className="headquarters-bridge-house-container2">
              <p className="headquarters-bridge-house1">
              2nd Floor College House 17 King Edwards Road,
              </p>
              <p className="queen-victoria-street1">
                RUISLIP, London, HA4 7AE, UNITED KINGDOM
              </p>
            </span>
          </b>
        </div>
      </div>
      <div className="divfooter-sub3">
          <div className="qr-curves-b-s-v-g">
          <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/privacy-policy"
        > <b >Privacy Policy</b></Link>
        <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/refund-policy"
        > <b >Refund Policy</b></Link>
        <Link style={{
          textDecoration: "none",
          color: "inherit"
        }}
          to="/term-condition"
        > <b >Terms & Conditions</b></Link>


            <div className="social-media">
              <b >Contact Us</b>

              <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareFacebook />
              </a>
              <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareInstagram />
              </a>
              <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquareYoutube />
              </a>
              <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </a>
              <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                <FaSquarePinterest />
              </a>

            </div>
          </div>
          <b>© 2024 Trend eSIM</b>
        </div>
    </footer>
  );
};

export default Emailiconframe;
