
import "./Term&Condition.css";
import Navigation1 from "../components/Navigation1";
import Divfooter1 from "../components/Divfooter1";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
  FaTiktok,
  FaSquarePinterest
} from "react-icons/fa6";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermCondition() {
  useEffect(() => {
    function handleScroll() {
      const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon");
      const elementAI = document.querySelector(".TrendAI-Logo");
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

      if (element && elementAI) {
        if (scrollHeight - scrollTop === clientHeight) {
          // Scroll has reached the end
          // console.log("Scroll reached the end");
          element.style.bottom = "75%";
          elementAI.style.bottom = "70%";
        } else {
          element.style.bottom = "-1%";
          elementAI.style.bottom = "15%";

        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="term-condition">
      <Navigation1 />
      <main className="footer-frame-1">
        <div className="header-texts">
          <h1 className="heading-2">Terms & Conditions</h1>
        </div>
        <section className="registration-texts">
          <div className="account-activation">
            {/* <div className="monty-uk-global-container-1">
              <span className="monty-uk-global-container1">
                <p className="monty-uk-global">
                   Agadir Adventure LTD , a company incorporated under the laws 
                  of England and Wales with registration number No.19221563 and
                  VAT 19731 00245 having its registered office at 2nd Floor College House 17 King Edwards Road,RUISLIP,London, HA4 7AE, UNITED KINGDOM.
                </p>
                <p className="bridge-house181-queen">

                </p>
              </span>
            </div> */}
            <div className="please-take-some-container">
              <p className="please-take-some">
                Please take some time to carefully read the following Terms &
                Conditions to ensure that you understand each provision. <strong> BY
                  ACCESSING OR USING THE SERVICE, YOU SIGNIFY THAT YOU HAVE READ,
                  UNDERSTOOD AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU ARE NOT ELIGIBLE
                  TO USE THE SERVICE, OR DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU
                  DO NOT HAVE OUR PERMISSION TO USE THE SERVICE.</strong>
              </p>
              <p className="no-agency-partnership">
                No agency, partnership, joint venture, or employment
                relationship is created as a result of these Terms & Conditions,
                and neither party has any authority of any kind to bind the
                other in any respect.
              </p>
              <p className="no-agency-partnership">
                These Terms and Conditions detail the agreement between the eSIM Trend user, (“You”), and the eSIM Trendl service provider, Mobell Communications Ltd. (“Us”/“We”).
              </p>
            </div>
          </div>
        </section>
        <section className="indemnification">
          <div className="service-description-montyesim-container">
            <span className="service-description-montyesim-container1">
              <p className="service-description">1. Service Description</p>
              <p className="montyesim-is-prepaid">
                TrendeSIM is prepaid eSIM profile reselling that allows
                travelers to use Data at the most competitive rates while abroad
                without inserting any physical SIM card.
              </p>
            </span>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="registration-by-registering-container">
            <span className="registration-by-registering-container1">
              <p className="registration">2. Returns:</p>
              <p className="by-registering-and">
                If you're not completely satisfied with the equipment for any reason you can take advantage of our Money Back Guarantee. Simply email us at returns@trendesim.com within 8 days of activation telling us why you are not satisfied and we will send you return instructions (if applicable). The Money Back Guarantee does not cover shipping charges, service charges (line rental, calls, text messages or cellular data) or services that have been purchased for use in an incompatible device.
              </p>
              <p className="time-on-the">
                The Money Back Guarantee is valid for a maximum of 3 months from purchase.
              </p>
            </span>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="privacy-policy-we-container">
            <span className="privacy-policy-we-container1">
              <p className="privacy-policy">3. Order Review:</p>
              <p className="we-are-committed">
                When placing your order we may perform some basic security and/or identification checks. In some instances we may need to contact you to obtain more information before fulfilling your order. Your order may be delayed or canceled if we are unable to obtain the required information.
              </p>
              <p className="information-from-unauthorized">
                The billing information you submit when placing your order will be used to create your account. The account holder will be responsible for all charges, unless otherwise stated. For your security account information will only be shared with the account holder.
              </p>
              {/* <p className="by-accessing-or">
                By accessing or using TrendeSIM, you agree to the use of
                information that you have provided when you registered to the
                service in order to keep in contact with you and seek your
                feedback.
              </p>
              <p className="we-may-use">
                We may use the information later to inform you about any future
                services that we may offer. At any time, you can ask us to
                adjust or delete the information you have previously shared with
                us.
              </p>
              <p className="in-addition-we">
                In addition, we automatically collect information about how you
                have used TrendeSIM, your devices operating systems, IP address,
                etc. We analyze this information to troubleshoot any issues
              </p>
              <p className="you-might-encounter">
                you might encounter, to work out how our products are being used
                and how to improve them.
              </p>
              <p className="this-privacy-policy">
                This Privacy Policy is subject to occasional revision, and if we
                make any changes in the way we use your personal information, we
                will send you a notification. Please note that, at all times,
                you
              </p>
              <p className="are-responsible-for">
                are responsible for updating your personal information.
              </p> */}
            </span>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="registered-i-p-rights">
            <div className="registered-intellectual-proper-container">
              <p className="registered-intellectual-proper">
                4. Shipping / Electronic Delivery:
              </p>
              <p className="you-are-not">
                Your order will be shipped using the delivery method selected at the point of purchase. A signature may be required at delivery. Some services may only be delivered to the address shown on your ID. International shipments may be subject to Customs clearance delays, and you may be liable for import duties and taxes - we have no control over these charges and cannot predict what they may be. There may be a fee for re-routing or canceling your order after it has been shipped. If your order has not arrived a refund may not be issued until the maximum estimated delivery date has been reached. If this date has passed and you have still not received your order, email support@trendesim.com. Refunds may not be issued if you have not allowed sufficient time for delivery. Please check the contents of your package carefully and notify us within 8 days of receipt of any missing or incorrect items.
              </p>
              <p className="services-without-our">
                eSIM purchases will be emailed to you or electronically delivered to your eSIM Trend MyAccount, (note that Japan Voice+Data eSIMs require a verification code to be physically shipped to the address on your ID). eSIM emails can take up to 1 business day to be delivered to you.
              </p>
              {/* <p className="monty-mobile-is">
                Trend Mobile is the owner or licensee of all intellectual and
                industrial property rights related to TrendeSIM Service. The use
                of TrendeSIM Service does not indicate any assignment, of all or
                part
              </p>
              <p className="of-such-rights">
                of such rights, to you or other service subscribers.
              </p> */}
            </div>
            <img
              className="whatsapp-logo6d974d7esvg-icon"
              loading="eager"
              alt=""
              src="/whatsapplogo6d974d7esvg1@2x.png"
              onClick={() => {
                window.open("https://api.whatsapp.com/send/?phone=447482774201")

              }}
            />
            <Link to="/trend-AI">
              <img
                className="TrendAI-Logo"
                alt=""
                src="/chatbot.png"
                title="Contact Our AI"
              />
            </Link>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="montyesim-account-i-container">
            <p className="montyesim-account">5. Collection:</p>
            {/* <p className="i-activation-of">i. Activation of TrendeSIM Service</p> */}
            <p className="you-can-request">
              If you have chosen to collect your order from one of our pick-up points, it will be ready and waiting for you on your chosen collection date. Only you, the account holder, can collect the order, and anyone collecting on your behalf will be turned away. You must show your passport as proof of ID upon collection. On rare occasions, your chosen collection point may be unable to fulfil your order. In this case, we will contact you as early as possible to make alternate arrangements.
            </p>
            {/* <p className="is-under-your">
              is under your own responsibility. You will immediately see the
              purchased TrendeSIM under the 'My MontyeSIMs' tab on the website
              and/or the app.The contract will be terminated in case the
            </p>
            <p className="customer-does-not">
              customer does not have an active data package after 3 months from
              buying the eSIM or has deleted the eSIM from the device.
            </p>
            <p className="ii-charges">ii. Charges</p>
            <p className="the-payment-shall">
              The payment shall be made by debit/credit card.
            </p>
            <p className="the-currency-of">
              The currency of the payment shall be in USD (United States
              Dollars).
            </p>
            <p className="the-creditdebit-card">
              The credit/debit card transaction will be processed and secured by
              a redirect link through a third party.TrendeSIM uses Stripe
              Elements to ensure the security of your payment transactions.
            </p>
            <p className="with-stripe-elements">
              With Stripe Elements, all customer details are encrypted and
              tokenized, providing a highly secure method for handling card
              payments. This encryption and tokenization process safeguards
            </p>
            <p className="your-sensitive-informationfur">
              your sensitive information.Furthermore, it's important to note
              that Stripe, our chosen payment processing partner, has undergone
              a rigorous audit by a PCI-certified auditor and has achieved
            </p>
            <p className="certification-as-a">
              certification as a PCI Service Provider Level 1. This
              certification represents the highest level of security and
              compliance available in the payments industry, underscoring our
              commitment to your
            </p>
            <p className="payment-securityrest-assured">
              payment security.Rest assured that when you make payments through
              TrendeSIM, your data is protected by robust security measures.By
              purchasing the service, you agree to bear the relevant
            </p>
            <p className="bundle-charges">bundle charges.</p>
            <p className="iii-termination">iii. Termination</p>
            <p className="you-have-the">
              1) You have the right to terminate the service at any time after
              the purchase of TrendeSIM Service.
            </p>
            <p className="monty-mobile-may">
              2) Trend Mobile may interrupt, suspend, cancel, or terminate your
              service without prior notice to you and with no liability on Trend
              Mobile for any breach and/or misconduct that violates these
            </p>
            <p className="terms-and-conditions">
              Terms and Conditions, including, but not limited to, the
              following:
            </p>
            <p className="a-using-andor">
              a) using and/or directing abusive, vulgar, derogatory,
              intimidating, and/or harassing language or conduct towards a Trend
              Mobile representative, officer, employee, agent, or authorized
            </p>
            <p className="retailer">retailer;</p>
            <p className="b-using-your">
              b) using your service for any unlawful, fraudulent, or illegal
              purpose;
            </p>
            <p className="c-using-the">
              c) using the service in any way that adversely affects our service
              or the Carrier s network;
            </p>
            <p className="d-violating-any">
              d) Violating any of the Terms or Conditions stated in this
              Agreement.
            </p>
            <p className="iv-consequences-of">iv. Consequences of Termination</p>
            <p className="if-the-service">
              If the service is deactivated, any remaining paid days will be
              lost. No refund for any unused elements of the TrendeSIM service
              will be available upon cancellation. Upon account termination
            </p>
            <p className="andor-expiration-you">
              and/or expiration, you lose all rights to access or use TrendeSIM
              Application.
            </p> */}
          </div>
          <div style={{
            marginTop: "20px"
          }} className="use-of-montyesim-container">
            <p className="use-of-montyesim">6. Installing & Reissuing eSIMs:</p>
            <p className="you-agree-to">
              eSIMs can only be installed once. Some eSIM services will require you to provide your device information; in this case your eSIM will only be compatible with that device and cannot be installed in any other device.
            </p>
            <p className="of-personal-data">
              If you delete your eSIM or change your device you will need to purchase a new eSIM.
            </p>
            {/* <p className="third-parties">third parties.</p>
            <p className="it-is-your">
              It is your own responsibility to ensure that your device is
              compatible with TrendeSIM and is not locked by any carrier, as
              this is a prerequisite to complete the purchase as device
              compatibility
            </p>
            <p className="may-depend-on">
              may depend on both of the carrier and the country of origin. To
              check that your device is compatible please dial *#06# and make
              sure that EID exist.
            </p>
            <p className="however-note-that">
              However, note that the TrendeSIM compatibility list is not
              exhaustive, as there might be newly announced TrendeSIM
              compatible devices that have not been yet added.
            </p>
            <p className="you-have-the1">
              You have the right to ask for a refund or request a change of
              bundle if the TrendeSIM can not be activated due to a proved
              valid technical problem originating from Trend Mobile. No refund
              will
            </p>
            <p className="be-applied-on">
              be applied on device not compatible as it cleary mentioned in
              the app that you need to make sure that your device is
              compatible before purchasing.
            </p>
            <p className="if-a-user">
              If a user fails to top up their eSIM account within a period of
              three months from the last top-up date, their eSIM will be
              deactivated manually by TrendeSIM. Once deactivated, the eSIM
              cannot
            </p>
            <p className="be-used-again">
              be used again unless the user takes specific steps to reactivate
              it.
            </p>
            <p className="upon-esim-deactivation">
              Upon eSIM deactivation, the user may lose any remaining data
              balance or credits associated with their account. TrendeSIM will
              not be responsible for any loss of data or balance resulting
              from
            </p>
            <p className="esim-deactivation">eSIM deactivation.</p>
            <p className="you-must-inform">
              You must inform us immediately if the device on which the
              Services are activated is lost or stolen. In the meantime, you
              will remain solely responsible for the usage of the Services on
              the device.
            </p>
            <p className="you-are-solely">
              You are solely responsible for all content, information and
              communications transmitted using the Service and agree that
              Trend Mobile will have no responsibility for your misuse of the
              service.
            </p> */}
          </div>
          <div style={{
            marginTop: "20px"
          }} className="indemnification-you-agree-container">
            <span className="indemnification-you-agree-container1">
              <p className="indemnification1">7. Billing, Usage & Additional Charges:</p>
              <p className="you-agree-to1">
                Equipment and shipping charges are taken from your preferred payment method (credit/debit card, PayPal, etc.) before shipping. You are also liable to pay for all usage (calls, text messages, cellular data & line rental) according to the tariff shown in your eSIM Trend MyAccount.
              </p>
              <p className="services-to-the">
                Usage charges (calls, text messages and cellular data) are taken from your preferred payment method as and when we receive the usage information from the networks, or on your next billing date (as shown in your eSIM Trend MyAccount). Due to delays in receiving information from networks, usage may be charged several months after it was used. You can view statements detailing the charges that we have taken, including fully itemized listings of your usage via your eSIM Trend MyAccount. Additional items, usage credit, and prepaid bundles are valid only for the time period specified and valid only for the phone/SIM Card/eSIM for which they have been purchased/applied. Additional items, usage credit, and prepaid bundles are non-refundable including any un-used minutes/data.
              </p>
              <p className="services-to-the">
                Our transactions are processed at our billing center in the UK; before traveling, you are advised to notify your preferred payment provider to expect charges from the UK.              </p>
              <p className="services-to-the">
                You must inform us of any changes to your preferred payment provider details. If we are unable to take payments we may suspend your service (which could result in permanent loss of service) until the outstanding charges are settled. Your debt may be passed to a collection agency. You will be liable for all costs and expenses incurred as a result of this action, including collection agency fees (based on a percentage at ximum of 50%), and reasonable legal fees.
              </p>
              <p className="services-to-the">
                If you are unhappy with your Trend eSIM charges for any reason email care@trendesim.com.              </p>
            </span>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="limitation-of-liability-container">
            <span className="limitation-of-liability-container1">
              <p className="limitation-of-liability">8. Cash Payments (Smart Pit):</p>
              <p className="except-to-the">
                This cash payment service was available to ongoing monthly customers who had made their first full month's line rental/plan fee payment via debit or credit card/PayPal. As of January 31st 2024, this payment method is limited to existing active users of the service; unfortunately, we are unable to accept any new applications to pay via this method.
              </p>
              <p className="reputation-business-interrupt">
                Each month, you will receive an email requesting cash payment of your bill within 10 days.
              </p>
              <p className="under-montyesim-services">
                You can view a fully itemized statement of your charges in your Trend eSIM  MyAccount.
              </p>
              <p className="individuals">Your line rental/plan fee will be charged upfront in full calendar months.</p>
              <p className="individuals">If you do not pay your bill within 10 days via cash payment, payment will be taken from your debit/credit card/PayPal account on record.</p>
              <p className="individuals">You must keep your debit/credit card/PayPal account up to date in your Trend eSIM  MyAccount. Failure to keep your debit/credit card/PayPal account up to date may result in disconnection of your service.</p>
              <p className="individuals">If we do not receive payment from you via cash, we retain the right to change your default payment method to debit/credit card/PayPal.</p>
              <p className="individuals">If we need to reissue a cash payment request to you due to late payment, an additional fee may be applied to your bill.</p>
            </span>
          </div>
          <div style={{
            marginTop: "20px"
          }} className="modification-we-reserve-container">
            <p className="modification">9. AliPay Payments (Japanese products only)</p>
            <p className="we-reserve-the">
              Each month, you will receive an email requesting payment of your bill within 5 days. You can view a fully itemized statement of your charges in your Trend eSIM  MyAccount.
            </p>
            <p className="database-or-content">
              If you do not pay your bill within 5 days your service may be suspended (which could result in permanent loss of service).
            </p>
            {/* <p className="of-the-services">
              of the Services without notice or liability on Trend Mobile. It is
              also your responsibility to check these Terms & Conditions
              periodically for changes. Your use of the Services is subject to
              the Terms
            </p>
            <p className="conditions-in-effect">
              & Conditions in effect at the time of such use. We may amend these
              terms from time to time. You shall receive a notification alert
              through the Application whenever an amendment has been
            </p>
            <p className="made-if-following">
              made. If, following receipt of such message you continue to use
              TrendeSIM, you will be deemed to have accepted such amendment.
            </p> */}
          </div>
          <div style={{ marginTop: "20px" }} className="governing-law-these-container">
            <span className="governing-law-these-container1">
              <p className="governing-law">10. Refunds:</p>
              <p className="these-terms">
                Refunds may take 7-10 business days to appear back in your account. The refund value may differ from the initial purchase value due to the daily conversion rate. In such instances, we are unable to process the difference between the two values.
              </p>
              {/* <p className="resolution-or-invalidity">
                resolution or invalidity of this contract or related to it,
                directly or indirectly, shall be submitted, with express waiver
                of the jurisdiction that may correspond to them, to the Courts
                of London.
              </p>
              <p className="notwithstanding-the-above">
                Notwithstanding the above, both parties will do everything
                reasonably necessary to try to resolve conflicts in an amicable
                manner.
              </p> */}
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="third-party-when-container">
            <span className="third-party-when-container1">
              <p className="third-party">11. Disputed Transactions:</p>
              <p className="when-you-access">
                If we are notified of a dispute raised via your payment provider we may suspend your service while the matter is dealt with. Any loss of funds due to a dispute may result in your details being passed on to our collection agency.
              </p>
              {/* <p className="responsible-or-liable">
                responsible or liable whatsoever for the content, functions,
                accuracy, legality, appropriateness or any other aspect of such
                websites or resources. You further acknowledge and agree that we
              </p>
              <p className="shall-not-be">
                shall not be responsible or liable, directly or indirectly, for
                any damage or loss caused or alleged to be caused by or in
                connection with the use of or reliance on any such content,
                goods or
              </p>
              <p className="services-available-on">
                services available on or through any such website or resource.
              </p> */}
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="no-waiver-our-container">
            <span className="no-waiver-our-container1">
              <p className="no-waiver">12. Change of Details:</p>
              <p className="our-failure-to">
                If your address, phone number or email address change, it is your responsibility to update the new details in your online account. Failure to update these details could result in missed communications and/or result in confidential information being misdirected, for which we will not be held responsible.
              </p>
              <p className="any-particular-instance">
                If your name has changed please let us know by email – support@trendesim.com
              </p>
              {/* <p className="written-notice-of">
                written notice of such waiver through one of our authorized
                representatives.
              </p> */}
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="force-majeure-we-container">
            <span className="force-majeure-we-container1">
              <p className="force-majeure">13. Plan Change Requests :</p>
              <p className="we-shall-not">
                You can request to change your plan by logging into your Trend eSIM MyAccount.
              </p>
              <p className="without-limitation-mechanical">
                eSIM/SIM customers can upgrade their plan at any time. If you upgrade your plan on the 1st of the month, your data allowance and plan fees increase on the 1st. If upgrading part way through a month, your data allowance will be increased within 24 hours, and you will be charged the difference between your current plan and the plan you are upgrading to. You will continue to be billed for your chosen plan on 1st of every month until you change your plan again or terminate your service.
              </p>
              <p className="terminated-provided-that">
                eSIM/SIM plan downgrades take effect from the 1st of the month. Refunds will not be given if you wish to downgrade your plan part way through the month.
              </p>
              <p className="terminated-provided-that">
                To ensure your downgrade can be processed by 1st of the month, you must submit your downgrade request more than 48 hours before the end of the month. Requests submitted within 48 hours of the end of the month will not be processed until the 1st of the following month and you will continue to pay your higher plan fee until your downgrade request has completed.              </p>
              <p className="terminated-provided-that">
                For WiFi customers, all plan change requests must be received by the 20th of the month to be processed on the 1st of the upcoming month. This includes upgrades, downgrades, and terminations. Any requests received after 20th of the month will not be processed until the 1st of the following month. Your plan fee will not change until your plan change request has been completed.
              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="warranties-montyesim-service-container">
            <p className="warranties">14. Network Coverage & Performance:</p>
            <p className="montyesim-service-will">
              You are advised to check the coverage area of the network provider before you sign up for our services. Even in areas where network coverage is provided, you may not be able to receive service when underground, inside buildings, between buildings or in areas where the radio waves are weak.
            </p>
            <p className="express-warranty-to">
              Any data speeds quoted are based on the best possible conditions, not actual speeds at all times. Actual speeds may vary, based on a number of factors beyond Trend eSIM /the network provider's control, such as network coverage, internet congestion, device, number/nature of applications running.
            </p>
            <p className="warranty-of-fitness">
              ome of our services support tethering/hotspot use, however, we cannot guarantee that this feature will work with all devices/operating systems. To find out more about tethering please visit our support pages and FAQs.
            </p>
            {/* <p className="which-it-is">
              which it is installed and its performance of all its identified
              functions. If the App does interfere with your intended or desired
              use of any mobile device, Trend Mobile shall bear no liability
            </p>
            <p className="whatsoever-for-such">whatsoever for such interference.</p>
            <p className="monty-mobile-is1">
              Trend Mobile is not responsible for detriments arising as a
              result, that the proposed service is not or not constantly
              available. Trend Mobile provides no guarantee of constant
              availability of the
            </p>
            <p className="network-service">network service.</p> */}
          </div>
          <div style={{ marginTop: "20px" }} className="refer-and-earn-container">
            <p className="refer-and-earn">15. Network Restrictions:</p>
            <p className="the-refer-and">
              Our network providers may temporarily restrict service where customers are deemed to be using excessive data/bandwidth. This is to prevent poor service for other customers, as customers using excessive data/bandwidth can:
            </p>
            {/* <p className="considered-successful">considered successful.</p> */}
            <ul>
              <li>Cause interference with other customers' communications</li>
              <li>Affect the ability of carriers to allocate communication capacity between customers</li>
              <li>Degrade the quality of other customers' communications</li>
            </ul>
            <p className="the-referrer-will">
              This restriction is enforced by the network provider and we have no control in removing or overriding it.
            </p>
            {/* <p className="the-user-will">
              The user will be eligible for a 10% cashback on their third
              transaction made through the TrendeSIM app.
            </p>
            <p className="to-qualify-for">
              To qualify for the cashback, the referred user must make a
              minimum of three transactions using TrendeSIM app.
            </p>
            <p className="the-cashback-will">
              The cashback will be calculated as 10% of the total amount spent
              on the referred user's third transaction made through TrendeSIM
              app, excluding any taxes, fees, or other charges.
            </p>
            <p className="the-cashback-amount">
              The cashback amount will be credited to the user's account
              within a specified timeframe after their third transaction.
            </p>
            <p className="the-cashback-can">
              The cashback can be used as credit for future purchases as one
              payment within the TrendeSIM app and cannot be transferred or
              redeemed for cash.
            </p>
            <p className="this-offer-is">
              This offer is valid for both new and existing users who refer
              and are referred by others through TrendeSIM app.
            </p>
            <p className="this-program-is">
              This program is subject to change or termination at our
              discretion without prior notice. Any changes or updates to the
              program will be communicated within TrendeSIM app.
            </p> */}
          </div>
          <div style={{ marginTop: "20px" }} className="data-measurement-and-container">
            {/* <span className="data-measurement-and-container1"> */}
            <p className="data-measurement-and">
              16. Service Changes:
            </p>
            <p className="data-shall-be">
              From time to time, we may make changes to our pricing/service offerings. In extreme cases, we may need to terminate certain services/options altogether. In these cases, we will always email you, giving as much notice as possible. Please be aware that, where these changes are prompted by our network partners, we may receive minimal notice of the change, limiting the amount of notice we are able to give to you. We will not be held responsible for emails not received for any reason, or for any harm/injury/inconvenience caused by the withdrawal of your Trend eSIM  service. Wherever possible, we will provide you with an alternative service option.
            </p>
            {/* <p className="and-systems-may">
              and systems may utilize Gibibytes (GiB) for data calculation.
              The distinction between a GB Gigabyte (1000^3) and a GiB
              Gibibyte (1024^3) should be recognized. While most data will be
            </p>
            <p className="calculated-in-gigabytes">
              calculated in Gigabytes, the variance in calculation standards
              may lead to differences in reported values when using different
              units of measurement. It is advisable to consider this
              distinction
            </p>
            <p className="when-interpreting-and">
              when interpreting and comparing data values across various
              platforms and systems.
            </p> */}
            {/* </span> */}
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">16. WiFi Calling:</p>
              <p className="if-you-have">
                If cellular network coverage is not available but you are able to connect to a WiFi network, you may still be able to place calls or send SMS text messages by using the "WiFi Calling" feature.
              </p>
              <p className="mail-to-supportmontyesimcom">
                Services used over the “WiFi Calling” feature are chargeable at the standard per minute / per message rates that are listed for your Trend eSIM  plan.
              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">17. Third-party Apps and Content:</p>
              <p className="if-you-have">
                Apps and content that can be purchased or downloaded on devices (such as games, productivity tools, wallpapers, ringtones, etc) are not sold or provided by us. We are not responsible for any failure, interruption or delay in downloading, installing, using, or transmitting the App or content, or for any trouble with the App or content, including any content or website accessible through the App.              </p>
              <p className="mail-to-supportmontyesimcom">
                If you use, download, or install Apps or content sold by third parties, you may be deemed to have agreed to their terms of use, privacy policies, and other terms. Some devices, Apps and content may connect to our network without your knowledge, which may incur charges. Third-party Apps and content may affect your device's software and settings.
              </p>
              <p className="mail-to-supportmontyesimcom">
                If calls are placed / messages are sent via a 3rd party application, (such as "Whatsapp" or "Line”), while connected to cellular data, this data usage will be deducted from your Trend eSIM data allowance and will be chargeable (if applicable) at the rate listed for your Trend eSIM plan.              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">18. Mobile Number Portability (MNP) / Porting:</p>
              <p className="if-you-have">
                MNP stands for Mobile Number Portability – otherwise known as porting. It’s a system that allows you to keep your phone number when you move to/from different providers.              </p>
              <p className="mail-to-supportmontyesimcom">
                MNP Number portability is not supported for the ‘Mobal Japan Unlimited v1’ service but is supported for all other Mobal Japan voice SIM services. If you’re not sure which version of the Mobal Japan voice SIM you are using, simply visit your Trend eSIM  MyAccount.              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">19. Loss or Theft:</p>
              <p className="if-you-have">
                You are liable for all usage charges (calls, text messages and cellular data) incurred until you notify us of the loss or theft. To request disconnection of the service visit your Trend eSIM MyAccount or call any of the following numbers immediately:
              </p>
              <p className="mail-to-supportmontyesimcom">
                Japan: +81 (0)3 4550 1524
              </p>
              <p className="mail-to-supportmontyesimcom">
                USA: +1 212 785 5800
              </p>
              <p className="mail-to-supportmontyesimcom">
                UK: +44 (0)1543 426999
              </p>
              <p className="mail-to-supportmontyesimcom">
                This emergency service is available 24-hours a day. Simply follow the instructions on the automated service.              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">20. Mobal's All Risk Protection Plan:</p>
              <p className="if-you-have">
                Mobal’s All Risk Protection Plan protects you from the charges that would normally be incurred if your Mobal SIM card/eSIM/device is lost, stolen or damaged; if your eSIM is accidentally deleted, or if your SIM/eSIM is no longer compatible when you upgrade/change your device.              </p>
              <p className="mail-to-supportmontyesimcom">
                Cover includes:
              </p>
              <ul>
                <li>A free replacement Mobal SIM card/eSIM/device</li>
                <li>Free standard shipping dependant on location</li>
                <li>Up to ¥50,000 worth of usage if your service is used after it is lost or stolen</li>
                <li>No deductibles</li>
              </ul>
              <p className="mail-to-supportmontyesimcom">
                The Trend eSIM All Risk Protection Plan is subject to the following terms: If your Mobal SIM card/eSIM/device is lost or stolen or your eSIM is deleted, you must notify Mobal immediately to request disconnection of the service. Mobal will then send you a claim form to complete. Failure to notify Mobal of the loss or theft could affect your right to claim. You must report the loss or theft to the police. You must notify Mobal of the Police Incident Number, the name and telephone number of the police station, and the date on which the incident was reported. Claims cannot be accepted unless this information is provided.
              </p>
              <p className="mail-to-supportmontyesimcom">
                You must take all reasonable precautions to protect the equipment from loss, theft, damage and deletion. This Plan does not cover any additional costs incurred as a consequence of the loss, theft, damage, deletion or fault of the equipment. This Plan does not cover any additional personal items. This Plan does not cover theft from an unattended motor vehicle. This Plan shall be void in the event of fraud, non-disclosure or alteration of risk. You are limited to one claim per 12-month period. We reserve the right to cancel the Plan, and in such an event, we will return the premium for the unexpired period. If you cancel, dispute or default on payment of the Trend eSIM All Risk Protection Plan you will no longer be covered.
              </p>
              <p className="mail-to-supportmontyesimcom">
                Your first year’s premium is taken upfront from your preferred payment method. To renew your Protection Plan, you don’t need to do anything – it will automatically renew around the anniversary of your contract, however we will email you prior to renewal giving you the opportunity to opt-out by visiting your Trend eSIM MyAccount.              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">21. Equipment Problems & Replacements:</p>
              <p className="if-you-have">
                If there is a fault with the equipment within 12 months of purchase, (or within 3 months for refurbished equipment), we will replace it. Please email support@trendesim.com.
              </p>
              <p className="mail-to-supportmontyesimcom">
                Any problems you have with the equipment must be reported to Trend eSIM as soon as possible. Refunds will not be issued if we were not given the opportunity to solve the problem. We shall not be held responsible for any loss, damage, injury or inconvenience arising from any aspect of our service. We shall not be held responsible for SIM cards/eSIMs purchased for use in incompatible or locked handsets. We will also not be held responsible for problems relating to the service provided by the phone networks.
              </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">22. Inactivity:</p>
              <p className="if-you-have">
                Services that require activation must be activated within 3 months of purchase, after this time you must purchase a new SIM card/eSIM. We reserve the right to suspend your service if your SIM card/eSIM does not incur any usage charges within a 12 month period.                </p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">23. Terminating Your Service:</p>
              <p className="if-you-have">
                If you wish to terminate your service please visit your Trend eSIM MyAccount and select the Cancel Service option. Line rental is non-refundable. With our fixed contract plans, you are committed to paying for the fixed period of service. If you terminate your contract within this time, you are liable to pay the remainder of the contract term.
              </p>
              <p className="if-you-have">
                You will remain liable for all usage charges (calls, text messages and cellular data) incurred until the service is terminated. You may not be able to terminate your service until you have cleared all outstanding payments on your account. Your account information will be retained for a minimum of 6 months from the point of termination.              </p>
              <p className="if-you-have">
                For WiFi customers, all termination requests must be received by the 20th of the month to be processed at the end of the current month. Any termination requests received after the 20th of the month will not be processed until the end of the following month. You will continue to be charged until your plan has been terminated.</p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">24. Restrictions:</p>
              <p className="if-you-have">
                Our shopping cart may set restrictions on the maximum number of products you can purchase. If you are a corporate customer and wish to order additional items beyond these limits, please visit mobal.com/corporate to request to set up a corporate account. If you purchase additional items without contacting us first, your orders may be cancelled and all active lines on your account(s) may be terminated. These order/account restrictions apply to customers/accounts across The Mobell Group.
              </p>
              <p className="if-you-have">
                You must not transfer the ownership of or sell or rent the equipment. We reserve the right to limit or reject orders/activations or to terminate the contract at any point, without explanation and such termination will be effective immediately. You are responsible for taking care of the equipment and ensuring that it is used responsibly. We will not be held responsible for any harm/injury/inconvenience caused by the termination of your service.</p>
              <p className="if-you-have">
                You must be over 18 years of age to use the Trend eSIM service.</p>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">25. Intellectual Property Rights:</p>
              <p className="if-you-have">
                You must not take any action that infringes the rights related to the constituent materials of the Mobal service, such as ownership rights; intellectual property rights, including copyrights; portrait rights; publicity rights, etc., without our permission.              </p>

            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">26. Privacy Policy:</p>
              <p className="if-you-have">
                You must not take any action that infringes the rights related to the constituent materials of the Mobal service, such as ownership rights; intellectual property rights, including copyrights; portrait rights; publicity rights, etc., without our permission.              </p>

            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">27. Anti-Harassment Policy:</p>
              <p className="if-you-have">
                We are committed to treating our customers with courtesy and respect, and we expect the same in return. We do not tolerate any form of inappropriate, intimidating, or aggressive language or behaviour. If a customer engages in such conduct, they will be politely asked to stop. Continued inappropriate behaviour may result in the cancellation of services and/or support.</p>

            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">28. Elimination of Anti-Social Forces:</p>
              <p className="if-you-have">
                By placing your order you confirm that you are not currently, never have been, and have no intention of becoming involved with any anti-social forces*. You confirm that your purchase will not be used in order to assist the funding of any anti-social forces or be used to facilitate anti-social forces. You also confirm that you are not associated with anti-social forces in any socially disapproved manner and that you don't use anti-social forces unjustly.
              </p>
              <p className="if-you-have">
                *Anti-social forces defined as below, but not limited to;
              </p>
              <ul>
                <li>A member, a quasi-member of, or affiliated to an Organised Crime Group</li>
                <li>A group specialising in intellectual crimes (tokushu chinou boryoku shudan)</li>
                <li>A person engaging in criminal activities under the pretext of conducting social campaign or political activities (shakaiundo hyobo goro)</li>
              </ul>
            </span>
          </div>
          <div style={{ marginTop: "20px" }} className="complaints-if-you-container">
            <span className="complaints-if-you-container1">
              <p className="complaints">29. Who We Are:</p>
              <p className="if-you-have">
                We are Mobell Communications Ltd. Mobell Communications Ltd. powers our family of brands:
              </p>
              <p className="if-you-have">
                Trend eSIM ; SIM Card Geek and Hanacell.
              </p>
              <p style={{ marginTop: "20px" }} className="if-you-have">
                Agadir Adventure Ltd
              </p>
              <p style={{ marginTop: "10px" }} className="if-you-have">
                2nd Floor College House 17 King Edwards Road,
              </p>
              <p style={{ marginTop: "10px" }} className="if-you-have">
                RUISLIP,
              </p>
              <p style={{ marginTop: "10px" }} className="if-you-have">
                London,
              </p>
              <p style={{ marginTop: "10px" }} className="if-you-have">
                HA4 7AE
              </p>
              <p style={{ marginTop: "10px" }} className="if-you-have">
                UNITED KINGDOM
              </p>
              <p style={{ marginTop: "25px" }} className="if-you-have">
                This agreement shall be governed by the laws of England and Wales. The courts of England and Wales shall have the non-exclusive jurisdiction to settle any disputes that may arise from or in connection with this agreement.
              </p>

            </span>
          </div>
        </section>
      </main>

      <Divfooter1 />
      <div className="divfooter-sub3">
        <div className="qr-curves-b-s-v-g">
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/privacy-policy"
          > <b >Privacy Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/refund-policy"
          > <b >Refund Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/term-condition"
          > <b >Terms & Conditions</b></Link>

          <div className="social-media">
            <b >Contact Us</b>

            <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareFacebook />
            </a>
            <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareInstagram />
            </a>
            <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareYoutube />
            </a>
            <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquarePinterest />
            </a>

          </div>
        </div>
        <b>© 2024 Trend eSIM</b>
      </div>
    </div>
  )
}
