import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import "./CheckForm.css";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import toast from 'react-hot-toast';
import { encrypt, decrypt } from '../crypto';

export default function CheckoutForm({
    paymentIntentId,
    clientSecret,
    onData,
    bundle,
    email
}) {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [isSucceeded, setisSucceeded] = useState();
    const [countdown, setCountdown] = useState(10);
    const [response, setResponse] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const username = process.env.REACT_APP_USERNAME_ESIM;
    const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
    const dataBody = encrypt({
        paymentIntentId: paymentIntentId,
        data: {
            price: bundle.subscriber_price,
            provider: bundle.provider,
            bundle_code: bundle.bundle_code,
            email: email
        },
        data_access: {
            username,
            password
        }
    });
    useEffect(() => {
        setTimeout(() => {
            setShowButtons(true);
        }, 500);
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

    }, [stripe, navigate]);

    const confirmPayment = async () => {
        let responseData = null;
        try {

            const res = await fetch(apiUrl + "confirm-payment", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: dataBody
                })
            });
            // console.log('Fetch response:', res);
            const encryptedData = await res.json();
            responseData = decrypt(encryptedData);
            // console.log("Data", responseData);
        } catch (error) {
            console.error("Error: ", error);
        }
        return responseData;
    };

    const sentDataToSheet = async (responseFromConfirmPayment) => {
        const { smdpAddress, ActivationCode, attachment, order_id } = responseFromConfirmPayment;
        // const buffer = Buffer.from(attachment, 'base64');
        // const base64String = buffer.toString('base64');
        const token = encrypt({
            data: {
                code: bundle.bundle_code,
                email: email,
                price: bundle.subscriber_price,
                name: bundle.bundle_name,
                order_id: order_id,
                smdpAddress: smdpAddress,
                ActivationCode: ActivationCode,
                attachment: attachment
            },
            data_access: {
                username,
                password
            }
        });
        fetch(apiUrl + "sendtosheet", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token: token
            })
        })
            .then((res) => res.json())
            .catch((error) => console.error("Error: ", error));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // console.log(stripe, elements);
            return;
        }

        setIsLoading(true);
        const test = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
        });
        const { error } = test
        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        } else {
            if (test.paymentIntent.status === "succeeded") {
                setisSucceeded(true);
                setMessage("Payment was successful, redirecting in a second Please wait...");
                const responseFromConfirmPayment = await confirmPayment();
                sentDataToSheet(responseFromConfirmPayment);
                onData(true);
                // console.log("Data", responseFromConfirmPayment);
                // setTimeout(() => {
                navigate("/payment-status",
                    {
                        state: {
                            redirect_status: 'succeeded',
                            response: responseFromConfirmPayment
                        }
                    });
                // }, 1500);
            } else {
                setisSucceeded(false);
                onData(false);
                setMessage("Payment was not successful!");
                // setTimeout(() => {
                navigate("/payment-status",
                    {
                        state: {
                            redirect_status: 'failed',
                            response: test.paymentIntent
                        }
                    });
                // }, 1500);
            }
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    }
    return (<>

        <form id="payment-form" className='check-form' onSubmit={handleSubmit}>

            <PaymentElement id="payment-element" options={paymentElementOptions} />
            {showButtons && <div className="buttons-pay">
                <button
                    // disabled={isLoading || !stripe || !elements} 
                    id="submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                    </span>
                </button>
                <button id="cancel" onClick={
                    async () => {
                        const token = encrypt({
                            paymentIntentId: paymentIntentId,
                            data_access: {
                                username,
                                password
                            }
                        });
                        const response = await fetch(apiUrl + "cancel-payment", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                token: token
                            })
                        });

                        const data = await response.json();

                        if (data.success) {
                            navigate("/plans");
                        } else {
                            // alert.error(data.message);
                            toast.error(data.message);
                        }
                    }
                }>
                    <span id="button-text">
                        {"Cancel"}
                    </span>
                </button>
            </div>}
            {/* Show any err2q  n or or success messages */}
            {message && <div id="payment-message" style={{
                color: isSucceeded ? 'green' : 'red'

            }}>{message}</div>}
        </form>
        {/* {isSucceeded && <div className={`payment-status ${isSucceeded ? 'success' : 'failed'}`}>
            <div className="payment-status-text">
                <h2>Check Out {isSucceeded ? 'successful ✅' : 'failure ❌'}</h2>
                <p>Your order might take some time to process. <br />
                    Check your order status at your email after about 1min. <br />
                    Incase of any inqueries contact the support at support@esimshop.com </p>
                <p>Redirecting to home page in <b style={{ color: isSucceeded ? 'green' : 'red' }}>{countdown}</b> seconds...</p>
            </div>
        </div>
        } */}
    </>
    );
}