import MessageTextarea from "./MessageTextarea";
import "./SendmessageButton.css";
import { useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Buffer } from "buffer";
import { encrypt } from '../crypto';

const SendmessageButton = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const SentMial = () => {
    if (email === "" || message === "" || !email.includes("@") || ![
      "mail.com", "gmail.com", "yahoo.com", "hotmail.com",
      "outlook.com", "aol.com", "icloud.com", "protonmail.com",
      "zoho.com", "yandex.com", "tutanota.com", "gmx.com",
      "yopmail.com", "mail.ru", "inbox.lv", "t-online.de",
      "web.de", "gmx.net", "mail.de", "email.com", "email.cz",
      "seznam.cz", "centrum.cz"].includes(email.split("@")[1])) {
      toast.error("Please fill all the fields with valid values",
        {
          style: {

            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        });
    } else {
      const token = encrypt({
        email,
        message,
        data_access: {
          username,
          password
        }
      });
      const conf = {
        method: 'post',
        url: apiUrl + 'send',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: token,
        },

      }
      const sendMailPromise = axios(conf)
      toast.promise(
        sendMailPromise,
        {
          pending: 'Sending message...',
          success: 'Message sent successfully',
          error: 'Error sending message'
        }
        ,
        {
          style: {
            fontFamily: 'sans-serif',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#ca3770',
          },
        }
      ).then(() => {
        setEmail("");
        setMessage("");
      });
    }
  }
  return (
    <div className="sendmessage-button">
      <MessageTextarea contactArrowa4cb5823svg="/contactarrowa4cb5823svg3.svg" />
      <div className="textarea2">
        <b className="label-email4">Email</b>
        <div className="faqsection">
          {/* <div className="input9" /> */}
          <input className="input9" type="text" value={email} onChange={(e)=>{
            setEmail(e.target.value.toLowerCase());
          }} />
        </div>
        <b className="label-message1">Message</b>
        <textarea className="textarea3" value={message} onChange={(e)=>{
          setMessage(e.target.value);
        
        }}/>
      </div>
      <button className="form-button" onClick={(e)=>{
        e.preventDefault();
        SentMial();
      }}>
        <b className="send-message2">Send message</b>
      </button>
    </div>
  );
};

export default SendmessageButton;
