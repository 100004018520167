import SendmessageButton from "../components/SendmessageButton";
import DivfaqSection from "../components/DivfaqSection";
import Divfooter2 from "../components/Divfooter2";
import "./WwwmontyesimcomByHtmltod2.css";
import { Toaster } from "react-hot-toast";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
  FaTiktok,
  FaSquarePinterest
} from "react-icons/fa6";
import { Link } from "react-router-dom";

import Navigation1 from "../components/Navigation1";
const WwwmontyesimcomByHtmltod2 = () => {
  return (
    <div className="wwwmontyesimcom-by-htmltod3">
      <Navigation1 />
      <main className="message-textarea1">
        <SendmessageButton />
        <DivfaqSection />
      </main>
      <Divfooter2 />
      <div className="divfooter-sub3">
        <div className="qr-curves-b-s-v-g">
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/privacy-policy"
          > <b >Privacy Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/refund-policy"
          > <b >Refund Policy</b></Link>
          <Link style={{
            textDecoration: "none",
            color: "inherit"
          }}
            to="/term-condition"
          > <b >Terms & Conditions</b></Link>


          <div className="social-media">
            <b >Contact Us</b>

            <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareFacebook />
            </a>
            <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareInstagram />
            </a>
            <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquareYoutube />
            </a>
            <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
              <FaSquarePinterest />
            </a>

          </div>
        </div>
        <b>© 2024 Trend eSIM</b>
      </div>

      <Toaster />
    </div>
  );
};

export default WwwmontyesimcomByHtmltod2;
